<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
             <el-option label="待审核" :value="1"></el-option>
          <el-option label="通过" :value="2"></el-option>
          <el-option label="拒绝" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addNew">新增空间</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.start_time }}</p>
          <p>结束:{{ row.end_time }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 1">待审核</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 2"
            >通过</el-tag
          >
           <el-tag type="danger" size="small" v-if="row.status === 3"
            >审核拒绝</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="updateInfo(row)"
            >编辑</el-button
          >
            <el-button v-if="row.status == 5" type="success" size="small" @click="updateStatus(row,2)"
            >解冻</el-button
          >
            <el-button v-if="row.status == 3" type="danger" size="small" @click="updateStatus(row,1)"
            >反审核</el-button
          >
            <el-button v-if="row.status == 1"  type="warning" size="small" @click="details(row)"
            >审核</el-button
          >
          <el-button v-if="row.status == 2"  type="warning" size="small" @click="openRefuse(row,5)"
            >冻结</el-button
          >
          <el-button  type="success" size="small" @click="details(row)"
            >详情</el-button
          >
           <el-button  type="primary" size="small" @click="getAccount(row)"
            >提现账户</el-button
          >
        </div>
      </template>
    </auto-table>


    <AddorEdit ref="add"></AddorEdit>

    <refusePop :show="refuseShow" @confirm="confirm"></refusePop>


  <el-dialog title="编辑" :visible.sync="accountShow" width="30%" :close-on-click-modal="false">
      <el-form>
        <el-form-item label="姓名" required >
          <el-input v-model="account.name" placeholder="支付宝实名"></el-input>
        </el-form-item>
         <el-form-item label="支付宝" required >
          <el-input v-model="account.aliPay" placeholder="支付宝账号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="accountShow = false">取 消</el-button>
        <el-button  type="primary" :loading="loading" @click="editAccount"
          >设置</el-button
        >
      </span>

    </el-dialog>


  </div>
</template>

<script>
import refusePop from '@/components/refusePop/refusePop.vue';
import AddorEdit from '../components/space/AddorEdit.vue';
export default {
  components:{
      AddorEdit,
      refusePop
  },
  data() {
    return {
      refuseShow:false,
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "城市", value: "city" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: '',
      curRow:{},
      accountShow:false,
      account:{},
      curAdmin:''
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    editAccount(){

        if(!this.account.name){
          this.$message("请填写姓名")
          return
        }
        if(!this.account.aliPay){
          this.$message("请填写账号")
          return
        }

        let data = {
          name:this.account.name,
          aliPay:this.account.aliPay
        }

        if(this.account.id){
          data.id = this.account.id
            this.$post('/user/rechargeInfo/update',data).then(res=>{
              this.$message('成功')
              this.accountShow = false
            })
        }else{
          data.admin_id = this.curAdmin
            this.$post('/user/rechargeInfo/insert',data).then(res=>{
              this.$message('成功')
              this.accountShow = false
            })
        }
    },
    getAccount(row){
      this.$get('/user/rechargeInfo/querySpaceAccount',{id:row.id}).then(res=>{
        let {admin,info} = res.data.data
          this.account = info || {}
          this.curAdmin = admin,
          this.accountShow = true
      })
    },
    openRefuse(item){
        this.curRow = item
        this.refuseShow = true
    },
    confirm(text){
      this.refuseShow = false
        this.$post('/shared/sharedSpace/update',{id:this.curRow.id,status:5,refuse_contect:text}).then(res=>{
          this.$message('成功')
          this.getList(1);
        })
    },
    details(row){
      this.$router.push({name:'sharedSpaceDetail',query:{id:row.id}})
    },
    addNew(){
      this.$refs.add.open()
    },

    updateInfo(row) {
       this.$refs.add.open(row)
    },
    updateStatus(row,status){
        this.$post('/shared/sharedSpace/update',{id:row.id,status}).then(res=>{
          this.$message('成功')
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/shared/sharedSpace/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>