export let addRule = {
    name: [
        { required: true, message: "场馆名称", trigger: "change" },
    ],
    address: [
        { required: true, message: "地址", trigger: "change" },
    ],
    longitude: [
        { required: true, message: "经度", trigger: "change" },
    ],
    latitude: [
        { required: true, message: "纬度", trigger: "change" },
    ],
    telephone: [
        { required: true, message: "场馆电话", trigger: "change" },
    ],

}

